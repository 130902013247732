import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'

export const useRoleStore = defineStore('talent', () => {

  // state
  const fields = ref([
    {
      label: "Design",
      slug: "design",
      roles: [
        {label: "Brand Designer", value: "brand-designer"},
        {label: "Customer Experience Designer", value: "customer-experience-designer"},
        {label: "Digital Designer", value: "digital-designer"},
        {label: "DTP Operator", value: "dtp-operator"},
        {label: "Graphic Designer", value: "graphic-designer"},
        {label: "Illustrator", value: "illustrator"},
        {label: "Motion Designer", value: "motion-designer"},
        {label: "Product Designer", value: "product-designer"},
        {label: "Service Designer", value: "service-designer"},
        {label: "UI Designer", value: "ui-designer"},
        {label: "UX Designer", value: "ux-designer"},
        {label: "Visual Designer", value: "visual-designer"},
        { label: "Web Designer", value: "web-designer" },
      ]
    },{
      label: "Advertising",
      slug: "advertising",
      roles: [
        { label: "Art Director", value: "art-director" },
        { label: "Campaign Manager", value: "campaign-manager" },
        { label: "Content Manager", value: "content-manager" },
        { label: "Content Strategist", value: "content-strategist" },
        { label: "Content Writer", value: "content-writer" },
        { label: "Copywriter", value: "copywriter" },
        { label: "Creative Director", value: "creative-director" },
        { label: "Creative Strategist", value: "creative-strategist" },
        { label: "Digital Strategist", value: "digital-strategist" },
        { label: "Paid Media Manager", value: "paid-media-manager" },
        { label: "Proofreader", value: "proofreader" },
        { label: "Public Relations Manager", value: "public-relations-manager" },
        { label: "SEO Specialist/Manager", value: "seo-specialist-manager" },
        { label: "Social Media Manager", value: "social-media-manager" },
      ]
    },{
      label: "Production",
      slug: "production",
      roles: [
        { label: "2D Animator", value: "2d-animator" },
        { label: "3D Animator", value: "3d-animator" },
        { label: "Colourist", value: "colourist" },
        { label: "Editor", value: "editor" },
        { label: "Line Producer", value: "line-producer" },
        { label: "Photographer", value: "photographer" },
        { label: "Post Production Producer", value: "post-production-producer" },
        { label: "Producer", value: "producer" },
        { label: "VFX Producer", value: "vfx-producer" },
        { label: "Videographer", value: "videographer" },
      ]
    },{
      label: "Engineering",
      slug: "engineering",
      roles: [
        { label: "Back-end Developer", value: "back-end-developer" },
        { label: "Data Engineer", value: "data-engineer" },
        { label: "Data Scientist", value: "data-scientist" },
        { label: "DevOps Engineer", value: "devops-engineer" },
        { label: "Front-end Developer", value: "front-end-developer" },
        { label: "Full-stack Developer", value: "full-stack-developer" },
        { label: "Java Developer", value: "java-developer" },
        { label: "Mobile App Developer", value: "mobile-app-developer" },
        { label: "QA Tester", value: "qa-tester" },
        { label: "Scrum Master", value: "scrum-master" },
        { label: "Solutions Architect", value: "solutions-architect" },
        { label: "Web Developer", value: "web-developer" },
      ]
    },{
      label: "People Managers",
      slug: "people-managers",
      roles: [
        { label: "Account Manager", value: "account-manager" },
        { label: "Campaign Manager", value: "campaign-manager" },
        { label: "Client Service Manager", value: "client-service-manager" },
        { label: "Influencer Manager", value: "influencer-manager" },
        { label: "Media Manager", value: "media-manager" },
        { label: "Operations Manager", value: "operations-manager" },
        { label: "Programme Manager", value: "programme-manager" },
        { label: "Project Manager", value: "project-manager" },
        { label: "Traffic Manager", value: "traffic-manager" },
      ]
    }
  ])

  const programmingLanguages = ref([
    { label: "C", value: "c" },
    { label: "C#", value: "c-sharp" },
    { label: "C++", value: "c-plus-plus" },
    { label: "CSS", value: "css" },
    { label: "Dart", value: "dart" },
    { label: "Elixir", value: "elixir" },
    { label: "Git", value: "git" },
    { label: "Go", value: "go" },
    { label: "HTML", value: "html" },
    { label: "Java", value: "java" },
    { label: "JavaScript", value: "javascript" },
    { label: "Kotlin", value: "kotlin" },
    { label: "Liquid", value: "liquid"},
    { label: ".NET", value: "net" },
    { label: "Objective-C", value: "objective-c" },
    { label: "PHP", value: "php" },
    { label: "Python", value: "python" },
    { label: "Ruby", value: "ruby" },
    { label: "Rust", value: "rust" },
    { label: "Scala", value: "scala" },
    { label: "Shell", value: "shell" },
    { label: "SQL", value: "sql" },
    { label: "Swift", value: "swift" },
    { label: "TypeScript", value: "typescript" },
  ])

  const langFrameworks = ref([
    {
      slug: "c",
      frameworks: [
        { label: "GTK+", value: "gtk+", type:"c" },
        { label: "Qt", value: "qt", type:"c" },
        { label: "GLib", value: "glib", type:"c" },
        { label: "Boost C++ Libraries", value: "boost-c++", type:"c" },
        { label: "SDL", value: "sdl", type:"c" },
        { label: "ncurses", value: "ncurses", type:"c" },
        { label: "WinAPI", value: "winapi", type:"c" }
      ]
    },
    {
      slug: "c-sharp",
      frameworks: [
        { label: "ASP.NET", value: "asp-net", type:"c-sharp" },
        { label: "Unity", value: "unity", type:"c-sharp" },
        { label: "Xamarin", value: "xamarin", type:"c-sharp" },
        { label: "Mono", value: "mono", type:"c-sharp" },
        { label: "Windows Forms", value: "windows-forms", type:"c-sharp" },
        { label: "WPF", value: "wpf", type:"c-sharp" },
        { label: "Blazor", value: "blazor", type:"c-sharp" },
        { label: "Avalonia", value: "avalonia", type:"c-sharp" }
      ]
    },
    {
      slug: "c-plus-plus",
      frameworks: [
        { label: "Qt", value: "qt", type:"c-plus-plus" },
        { label: "Boost C++ Libraries", value: "boost-c++", type:"c-plus-plus" },
        { label: "wxWidgets", value: "wxwidgets", type:"c-plus-plus" },
        { label: "Poco", value: "poco", type:"c-plus-plus" },
        { label: "FLTK", value: "fltk", type:"c-plus-plus" },
        { label: "VTK", value: "vtk", type:"c-plus-plus" },
        { label: "OpenCV", value: "opencv", type:"c-plus-plus" },
        { label: "Cinder", value: "cinder", type:"c-plus-plus" }
      ]
    },
    {
      slug: "css",
      frameworks: [
        { label: "Bootstrap", value: "bootstrap", type:"css" },
        { label: "Tailwind CSS", value: "tailwind-css", type:"css" },
        { label: "Bulma", value: "bulma", type:"css" },
        { label: "Materialize", value: "materialize", type:"css" },
        { label: "Foundation", value: "foundation", type:"css" },
        { label: "Semantic UI", value: "semantic-ui", type:"css" },
        { label: "Pure", value: "pure", type:"css" },
        { label: "Skeleton", value: "skeleton", type:"css" }
      ]
    },
    {
      slug: "dart",
      frameworks: [
        { label: "Flutter", value: "flutter", type:"dart" },
        { label: "AngularDart", value: "angulardart", type:"dart" },
        { label: "Aqueduct", value: "aqueduct", type:"dart" },
        { label: "Angel", value: "angel", type:"dart" },
        { label: "Redstone", value: "redstone", type:"dart" },
        { label: "Shelf", value: "shelf", type:"dart" },
        { label: "RxDart", value: "rxdart", type:"dart" },
        { label: "DartPad", value: "dartpad", type:"dart" }
      ]
    },
    {
      slug: "elixir",
      frameworks: [
        { label: "Phoenix", value: "phoenix", type:"elixer" },
        { label: "Nerves", value: "nerves", type:"elixer" },
        { label: "Absinthe", value: "absinthe", type:"elixer" },
        { label: "Ecto", value: "ecto", type:"elixer" },
        { label: "Oban", value: "oban", type:"elixer" },
        { label: "Broadway", value: "broadway", type:"elixer" },
        { label: "Quantum", value: "quantum", type:"elixer" },
        { label: "Crawly", value: "crawly", type:"elixer" }
      ]
    },
    {
      slug: "git",
      frameworks: [
        { label: "GitHub", value: "github", type:"git" },
        { label: "GitLab", value: "gitlab", type:"git" },
        { label: "Bitbucket", value: "bitbucket", type:"git" },
        { label: "Gitea", value: "gitea", type:"git" },
        { label: "Gogs", value: "gogs", type:"git" },
        { label: "GitKraken", value: "gitkraken", type:"git" },
        { label: "SourceTree", value: "sourcetree", type:"git" },
        { label: "Git Extensions", value: "git-extensions", type:"git" }
      ]
    },
    {
      slug: "go",
      frameworks: [
        { label: "Gin", value: "gin", type:"go" },
        { label: "Echo", value: "echo", type:"go" },
        { label: "Beego", value: "beego", type:"go" },
        { label: "Revel", value: "revel", type:"go" },
        { label: "Iris", value: "iris", type:"go" },
        { label: "Buffalo", value: "buffalo", type:"go" },
        { label: "Fiber", value: "fiber", type:"go" },
        { label: "Gorilla", value: "gorilla", type:"go" }
      ]
    },
    {
      slug: "html",
      frameworks: [
        { label: "Wix", value: "wix", type:"html" },
        { label: "Squarespace", value: "squarespace", type:"html" },
        { label: "Webflow", value: "webflow", type:"html" },
        { label: "WordPress", value: "wordpress", type:"html" }
      ]
    },
    {
      slug: "java",
      frameworks: [
        { label: "Spring", value: "spring", type:"java" },
        { label: "Hibernate", value: "hibernate", type:"java" },
        { label: "JSF", value: "jsf", type:"java" },
        { label: "Struts", value: "struts", type:"java" },
        { label: "Vaadin", value: "vaadin", type:"java" },
        { label: "GWT", value: "gwt", type:"java" },
        { label: "Play", value: "play", type:"java" },
        { label: "Grails", value: "grails", type:"java" }
      ]
    },
    {
      slug: "javascript",
      frameworks: [
        { label: "Node.js", value: "node-js", type:"javascript" },
        { label: "Express", value: "express", type:"javascript" },
        { label: "React", value: "react", type:"javascript" },
        { label: "Angular", value: "angular", type:"javascript" },
        { label: "Vue", value: "vue", type:"javascript" },
        { label: "Svelte", value: "svelte", type:"javascript" },
        { label: "Ember", value: "ember", type:"javascript" },
        { label: "Backbone", value: "backbone", type:"javascript" },
        { label: "Meteor", value: "meteor", type:"javascript" },
        { label: "Aurelia", value: "aurelia", type:"javascript" }
      ]
    },
    {
      slug: "kotlin",
      frameworks: [
        { label: "Spring", value: "spring" },
        { label: "Ktor", value: "ktor" },
        { label: "Micronaut", value: "micronaut" },
        { label: "Vert.x", value: "vert-x" },
        { label: "Javalin", value: "javalin" },
        { label: "Spark", value: "spark" },
        { label: "Jooby", value: "jooby" },
        { label: "Ninja", value: "ninja" }
      ]
    },
    {
      slug: "liquid",
      frameworks: [
        { label: "Shopify", value: "shopify" },
        { label: "Jekyll", value: "jekyll" }
      ]
    },
    {
      slug: ".net",
      frameworks: [
        { label: "ASP.NET", value: "asp-net" },
        { label: "Blazor", value: "blazor" },
        { label: "Entity Framework", value: "entity-framework" },
        { label: "WPF", value: "wpf" },
        { label: "Windows Forms", value: "windows-forms" },
        { label: "Xamarin", value: "xamarin" },
        { label: "Mono", value: "mono" },
        { label: "Nancy", value: "nancy" }
      ]
    },
    {
      slug: "objective-c",
      frameworks: [
        { label: "Cocoa", value: "cocoa" },
        { label: "Cocoa Touch", value: "cocoa-touch" },
        { label: "Core Data", value: "core-data" },
        { label: "Core Animation", value: "core-animation" },
        { label: "Core Image", value: "core-image" },
        { label: "Core Audio", value: "core-audio" },
        { label: "Core Graphics", value: "core-graphics" },
        { label: "Core Foundation", value: "core-foundation" }
      ]
    },
    {
      slug: "php",
      frameworks: [
        { label: "Laravel", value: "laravel" },
        { label: "Symfony", value: "symfony" },
        { label: "CodeIgniter", value: "codeigniter" },
        { label: "CakePHP", value: "cakephp" },
        { label: "Zend", value: "zend" },
        { label: "Yii", value: "yii" },
        { label: "Phalcon", value: "phalcon" },
        { label: "Slim", value: "slim" },
        { label: "Joomla", value: "joomla" },
        { label: "Drupal", value: "drupal" },
        { label: "Wordpress", value: "wordpress" },
        { label: "Statamic", value: "statamic" },
        { label: "Magento", value: "magento" },
      ]
    },
    {
      slug: "python",
      frameworks: [
        { label: "Django", value: "django" },
        { label: "Flask", value: "flask" },
        { label: "Pyramid", value: "pyramid" },
        { label: "Bottle", value: "bottle" },
        { label: "Tornado", value: "tornado" },
        { label: "Web2py", value: "web2py" },
        { label: "CherryPy", value: "cherrypy" },
        { label: "TurboGears", value: "turbogears" }
      ]
    },
    {
      slug: "ruby",
      frameworks: [
        { label: "Ruby on Rails", value: "ruby-on-rails" },
        { label: "Sinatra", value: "sinatra" },
        { label: "Padrino", value: "padrino" },
        { label: "Hanami", value: "hanami" },
        { label: "Cuba", value: "cuba" },
        { label: "Volt", value: "volt" },
        { label: "Grape", value: "grape" },
        { label: "Scorched", value: "scorched" }
      ]
    },
    {
      slug: "rust",
      frameworks: [
        { label: "Actix", value: "actix" },
        { label: "Rocket", value: "rocket" },
        { label: "Tide", value: "tide" },
        { label: "Warp", value: "warp" },
        { label: "Nickel", value: "nickel" },
        { label: "Iron", value: "iron" },
        { label: "Gotham", value: "gotham" },
        { label: "Rocket", value: "rocket" }
      ]
    },
    {
      slug: "scala",
      frameworks: [
        { label: "Play", value: "play" },
        { label: "Lift", value: "lift" },
        { label: "Scalatra", value: "scalatra" },
        { label: "Finatra", value: "finatra" },
        { label: "Akka", value: "akka" },
        { label: "Scalaz", value: "scalaz" },
        { label: "Slick", value: "slick" },
        { label: "Cats", value: "cats" }
      ]
    },
    {
      slug: "shell",
      frameworks: [
        { label: "Bash", value: "bash" },
        { label: "Zsh", value: "zsh" },
        { label: "Fish", value: "fish" },
        { label: "PowerShell", value: "powershell" },
        { label: "Tcsh", value: "tcsh" },
        { label: "Ksh", value: "ksh" },
        { label: "Csh", value: "csh" },
        { label: "Dash", value: "dash" }
      ]
    },
    {
      slug: "sql",
      frameworks: [
        { label: "MySQL", value: "mysql" },
        { label: "PostgreSQL", value: "postgresql" },
        { label: "SQLite", value: "sqlite" },
        { label: "MariaDB", value: "mariadb" },
        { label: "Oracle", value: "oracle" },
        { label: "SQL Server", value: "sql-server" },
        { label: "DB2", value: "db2" },
        { label: "Sybase", value: "sybase" }
      ]
    },
    {
      slug: "swift",
      frameworks: [
        { label: "UIKit", value: "uikit" },
        { label: "SwiftUI", value: "swiftui" },
        { label: "SpriteKit", value: "spritekit" },
        { label: "SceneKit", value: "scenekit" },
        { label: "ARKit", value: "arkit" },
        { label: "Core Data", value: "core-data" },
        { label: "Core Animation", value: "core-animation" },
        { label: "Core Graphics", value: "core-graphics" }
      ]
    },
    {
      slug: "typescript",
      frameworks: [
        { label: "React", value: "react" },
        { label: "Angular", value: "angular" },
        { label: "Vue", value: "vue" },
        { label: "Svelte", value: "svelte" },
        { label: "Ember", value: "ember" },
        { label: "Backbone", value: "backbone" },
        { label: "Meteor", value: "meteor" }
      ]
    },
  ])

  const generalCloud = ref([
    { label: "Amazon Web Services", value: "aws", type: "cloud" },
    { label: "Google Cloud Platform", value: "gcp", type: "cloud" },
    { label: "Microsoft Azure", value: "azure", type: "cloud" },
    { label: "Firebase", value: "firebase", type: "cloud" },
    { label: "Heroku", value: "heroku", type: "cloud" },
    { label: "Netlify", value: "netlify", type: "cloud" },
    { label: "Vercel", value: "vercel", type: "cloud" },
    { label: "DigitalOcean", value: "digitalocean", type: "cloud" }
  ])

  const designSoftware = ref([
    { label: "Adobe Photoshop", value: "photoshop", type: "software" },
    { label: "Adobe Illustrator", value: "illustrator", type: "software" },
    { label: "Adobe InDesign", value: "indesign", type: "software" },
    { label: "Adobe After Effects", value: "after-effects", type: "software" },
    { label: "Adobe XD", value: "xd", type: "software" },
    { label: "Adobe Spark", value: "spark", type: "software" },
    { label: "Adobe Fuse", value: "fuse", type: "software" },
    { label: "Sketch", value: "sketch", type: "software" },
    { label: "Figma", value: "figma", type: "software" },
    { label: "Canva", value: "canva", type: "software" },
    { label: "InVision", value: "invision", type: "software" },
    { label: "Zeplin", value: "zeplin", type: "software" },
    { label: "Marvel", value: "marvel", type: "software" },
    { label: "Principle", value: "principle", type: "software" },
  ])

  const softSkills = ref([
    { label: "Adaptability", value: "adaptability", type: "soft" },
    { label: "Collaboration", value: "collaboration", type: "soft" },
    { label: "Communication", value: "communication", type: "soft" },
    { label: "Creativity", value: "creativity", type: "soft" },
    { label: "Critical Thinking", value: "critical-thinking", type: "soft" },
    { label: "Empathy", value: "empathy", type: "soft" },
    { label: "Leadership", value: "leadership", type: "soft" },
    { label: "Problem Solving", value: "problem-solving", type: "soft" },
    { label: "Time Management", value: "time-management", type: "soft" },
    { label: "Work Ethic", value: "work-ethic", type: "soft" }
  ])

  const hardSkills = ref([
    { label: "Research", value: "research", type: "hard" },
    { label: "Data Analysis", value: "data-analysis", type: "hard" },
    { label: "Project Management", value: "project-management", type: "hard" },
    { label: "Quality Assurance", value: "quality-assurance", type: "hard" },
    { label: "Attention to Detail", value: "attention-to-detail", type: "hard" },
    { label: "Technical Skills", value: "technical-skills", type: "hard" },
    { label: "Self Starter", value: "self-starter", type: "hard" },
    { label: "Analytical Skills", value: "analytical-skills", type: "hard" },
    { label: "Organisational Skills", value: "organisational-skills", type: "hard" }
  ])

  const getAllSkills = computed(() => {
    return [
      ...softSkills.value,
      ...hardSkills.value
    ]
  })

  const getFieldRoles = (slug: string) => {
    return fields.value.find(field => field.slug === slug)
  }

  const getLanguages = computed(() => {
    return programmingLanguages.value
  })

  const getFrameworks = computed(() => {
    return langFrameworks.value
  })

  const getGeneralCloud = computed(() => {
    return generalCloud.value
  })

  const getGeneralCMS = computed(() => {
    return generalCMS.value
  })

  const getDesignSoftware = computed(() => {
    return designSoftware.value
  })

  // getters
  
  return {
    // state
    
    //getters
    getFieldRoles,
    getLanguages,
    getFrameworks,
    getGeneralCloud,
    getGeneralCMS,
    getDesignSoftware,
    getAllSkills,

    // mutations
  }
})
